<template>
  <div style="height: inherit">
  <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalProducts }} kayıt bulundu
              </div>
            </div>
            <div class="view-options d-flex">
              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <!-- <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Ürünleri ara"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row> -->
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        :class="innerFlow ? 'innerFlowCommerce' : 'outerFlowCommerce'"
        no-body
        @click="goToDetail(product.id)"
      >
        
        <div class="item-img text-center">
          <!--   --> 
          <!-- <b-link :to="{ name: 'apps-e-commerce-product-details', params: { slug: product.slug } }"> -->
          <b-carousel
            id="carousel-interval"
            :interval="50000000"
            controls
            indicators
          >
            <b-carousel-slide v-for="(p,index) in product.images" :key="index" :img-src="p" />
          </b-carousel>
          <!-- </b-link> -->
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>
            <div>
              <h6 class="item-price">
                ₺{{ product.price.toFixed(2) }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'apps-e-commerce-product-details', params: { slug: product.slug } }"
            >
              {{ product.name }}
            </b-link>
            <b-card-text class="item-company">
              By <b-link class="ml-25">
                {{ product.brand }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ product.title }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                ₺{{ product.price.toFixed(2) }}
              </h4>
            </div>
          </div>
          <div class="mb-2"/>
          <b-button
            v-if="innerFlow"
            :variant="selectedProduct.indexOf(product.id) > -1 ? 'outline' : 'primary'"
            tag="a"
            class="btn-wishlist mb-2"
            @click="e => $emit('checked', selectedProduct.indexOf(product.id) === -1, product.id)"
          >
            <feather-icon
              icon="HeartIcon"
              class="mr-50"
              :class="{'text-danger': product.isInWishlist}"
            />
            <span>{{selectedProduct.indexOf(product.id) > -1 ? 'Listeden Kaldır' : 'Listeye Ekle'}}</span>
          </b-button>
          <!-- <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="handleCartActionClick(product)"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
            />
            <span>Sepete Ekle</span>
          </b-button> -->
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.skip"
            :total-rows="totalProducts"
            :per-page="filters.take"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :class="innerFlow ? 'innerFlow' : 'outerFlow'"
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BCarousel, BCarouselSlide, BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'


export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCarousel,
    BFormCheckbox,
    BCarouselSlide,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    // SFC
    ShopLeftFilterSidebar,
  },
  data() {
    return {
      filterOptions: {},
      filters: {
        searchText: '',
        priceRangeDefined: 'all',
        priceRange: [0, 100],
        categories: [],
        brands: [],
        ratings: null,
        maxPrice: '',
        minPrice: '',
        skip: 1,
        take: 12,
      },
      debounce: null,
      mqShallShowLeftSidebar: true,
      products: [],
      totalProducts: 3,
      sortBy: { text: 'Featured', value: 'featured' },
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ]
    }
  },
  props: {
    innerFlow: false,
    selectedProduct: []
  },
  watch:{
    'filters.categories'(newVal) {
      this.getProducts()
    },
    'filters.minPrice'(newVal) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.getProducts()
      }, 600)
    },
    'filters.maxPrice'(newVal) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.getProducts()
      }, 600)
    },
    'filters.skip'(newVal) {
      this.getProducts()
    },
    'filters.searchText'(newVal) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.getProducts()
      }, 600)
    }
  },
  mounted(){
    this.getCategories()
    this.getProducts()
  },
  methods: {
    goToDetail(id) {
      if(this.innerFlow) return
      this.$router.push({ name: 'apps-e-commerce-product-details', params: { slug: id } })
    },
    getCategories() {
      this.$http.get('/api/v1/categories').then(res => { 
        this.filterOptions.categories = res.data.data.map(item => {return {value:item.id, text: item.title}})
        this.mqShallShowLeftSidebar = false;
      })
      
    },
    getProducts(){
      let pathName = `?page=${this.filters.skip}&pageSize=${this.filters.take}`
      if(this.filters.categories.length > 0) pathName += this.filters.categories.map(item => '&selectedCategoryIds=' + item).join("")
      if(this.filters.minPrice) pathName += '&minPrice=' + this.filters.minPrice
      if(this.filters.maxPrice) pathName += '&maxPrice=' + this.filters.maxPrice
      if(this.filters.searchText) pathName += '&searchText=' + this.filters.searchText
      this.$http.get('/api/v1/products' + pathName).then(res => { 
        this.products = res.data.data 
        this.totalProducts = res.data.totalCount
      })
    }
  },
   
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.checkboxforproduct{
  position: absolute !important;
  top: -5px;
  left: -8px;
  z-index: 99999;
}
.innerFlowCommerce{
  max-width: 265px !important;
}
</style>
